import React from "react";
import PageHeaderContent from "../PageHeader";
import {BsInfoCircleFill} from 'react-icons/bs'

const Resume = () => {
    return(
        <section id="resume" className="resume">
            <PageHeaderContent
            headerText="My Resume"
            icon={<BsInfoCircleFill size={40} />}
            />
        </section>
    )
}

export default Resume;